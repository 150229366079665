import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 25px;
`

export const Title = styled.div`
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Dancing Script', cursive;
  color: #fff;
  font-size: 31px;
  user-select: none;
  cursor: pointer;
`

export const Content = styled.div`
  background-color: #fff;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 11px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
